import SiteWrapper from "../../../../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../../../../assets/images/pagerduty_dxable_og.png";

import "./thank-you@gen.scss";

const GeneratedResourcesStateOfDigitalOperations2021FormThankYou = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>
          ダウンロードありがとうございます｜PagerDuty正規代理店（株）Digital
          Stacks
        </title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="フォームにご記入いただき、eBook をダウンロードしていただきありがとうございます。"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          name="description"
          content="フォームにご記入いただき、eBook をダウンロードしていただきありがとうございます。"
        />

        <meta
          property="og:title"
          content="ダウンロードありがとうございます｜PagerDuty正規代理店（株）Digital Stacks"
        />

        <meta
          property="og:description"
          content="フォームにご記入いただき、eBook をダウンロードしていただきありがとうございます。"
        />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/resources/state-of-digital-operations-2021/form/thank-you/"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/resources/state-of-digital-operations-2021/form/thank-you/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <div className="digitalOperationThankyouWrapper">
        <div className="w-full px-[25px]">
          <h1 className="font-bold text-2xl text-[#2A2A2A] max-w-[600px] mb-4 mx-auto text-center">
            資料ダウンロードのお申し込みをいただき
            <br />
            ありがとうございます。
          </h1>
          <p className="text-[#4B4B4B] leading-6 text-base tracking-[3%] mb-[30px] text-center">
            こちらからダウンロードしてお読みください。
          </p>
          <a
            href="/221127_D03_The_State_of_Digital_Operations_Report_2021_JPN_c4f6aa1320.pdf"
            download="221127_D03_The_State_of_Digital_Operations_Report_2021_JPN_c4f6aa1320.pdf"
            target="_blank"
            className="bg-[#4EB346] hover:bg-opacity-80 rounded-[22px] hover:text-white mx-auto block w-fit text-white text-base px-6 py-2 transition-all duration-300"
          >
            ダウンロード
          </a>
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedResourcesStateOfDigitalOperations2021FormThankYou;
